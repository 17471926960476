import { ITranslationKeys } from '../../i18n/types';
import { ISelectOption } from '../../types';

export const onNetShotCategoryOption: ISelectOption = {
  value: 'SG',
  label: ITranslationKeys.onNet,
};
export const goalShotCategoryOption: ISelectOption = { value: 'G', label: ITranslationKeys.goals };
export const blockedShotCategoryOption: ISelectOption = {
  value: 'B',
  label: ITranslationKeys.blocked,
};
export const missedShotCategoryOption: ISelectOption = {
  value: 'M',
  label: ITranslationKeys.missedShots,
};
export const postsShotCategoryOption: ISelectOption = {
  value: 'P',
  label: ITranslationKeys.posts,
};

export const baseShotCategoryOptions: ISelectOption[] = [
  onNetShotCategoryOption,
  goalShotCategoryOption,
];

export const shotCategoryOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.allShotAttempts },
  ...baseShotCategoryOptions,
];

export const shotLocationOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  { value: 'slot', label: ITranslationKeys.inSlot },
];
