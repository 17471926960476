import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  CommonTable,
  DataSettingsModal,
  Loading,
  TableToolbar,
} from '../../../../components';
import {
  filteredMainFilterDataSelector,
  getGoalkeepersStats,
  getRanks,
  selectDataSettingsFilter,
  selectGoalkeepers,
  selectMainFilter,
  selectMetrics,
  selectRanks,
  selectTeams,
  setIsGoalkeepersPercentilesActive,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useHandleOnSubmit,
  useNormalizeMetrics,
  usePrepareBaseRequestBody,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  IEntity,
  IExportRows,
  IGoalkeeperGoalStatsRequestBody,
  IGoalkeeperStatsTableData,
  IMainFilterForm,
} from '../../../../types';
import {
  createClassNames,
  getPlayerPositionShortcut,
  getPlayerStick,
  handleExportToXLSX,
  roundNumberTo2Decimals,
} from '../../../../utils';
import './TabsContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export const useFetchGoalkeeperStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { isPercentilesActive } = useAppSelector(selectGoalkeepers);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchGoalkeeperStats = (data: Partial<IMainFilterForm>, isPercentiles?: boolean) => {
    if (isPercentilesActive) {
      dispatch(setIsGoalkeepersPercentilesActive(false));
    }

    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IGoalkeeperGoalStatsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(undefined, IEntity.goalkeepers),
    };

    dispatch(
      getGoalkeepersStats({
        competitionsUuids,
        body: requestBody,
        teamUuid: selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : undefined,
        isPercentile: !!isPercentiles,
      }),
    );
    if (isPercentiles) {
      dispatch(
        getRanks({
          competitionsUuids,
          body: requestBody,
          isGoalkeepers: true,
        }),
      );
    }
  };

  return fetchGoalkeeperStats;
};

const classNames = createClassNames('goalkeepers-tabs-content');

export const TabsContent = () => {
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const { byId, isStatsLoading, isPercentilesActive, isPercentilesLoading } =
    useAppSelector(selectGoalkeepers);
  const { isLoading } = useAppSelector(selectRanks);
  const teams = useAppSelector(selectTeams);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const isInitialMountRef = useRef(true);

  const fetchGoalkeeperStats = useFetchGoalkeeperStats();
  const handleOnSubmit = useHandleOnSubmit((values, config) => {
    fetchGoalkeeperStats(values, config?.isPercentiles);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  const isSomethingLoading = isStatsLoading || isLoading;

  useRefetchContent({ handleOnSubmit, isLoading: isSomethingLoading });

  const handleToggleLeaguePercentile = () => {
    dispatch(setIsGoalkeepersPercentilesActive(!isPercentilesActive));
    if (!isPercentilesActive) {
      handleOnSubmit({ isPercentiles: true });
    }
  };

  const commonHeader = [
    'Hokej.cz ID',
    'Jméno brankáře',
    'Věk',
    'Držení hole',
    'Tým',
    'Pozice',
    'GP',
    'TOI',
  ];
  const tableRowCallback = (row: IGoalkeeperStatsTableData): IExportRows => {
    const goalkeeper = byId[row.player.id];
    const team = teams.byId[row.teamId];

    return {
      hokejczId: goalkeeper.hokejczId.toString(),
      goalkeeperName: row.player.displayName,
      age: goalkeeper.age,
      stick: t(getPlayerStick(goalkeeper.stick)).toString(),
      team: team.shortcut,
      position: t(getPlayerPositionShortcut(goalkeeper.position)).toString(),
      gp: row.gp,
      toi: roundNumberTo2Decimals(row.toi / 60),
    };
  };

  if (isSomethingLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  return (
    <div className={classNames()} data-testid='goalkeepers-page__tabs-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        isPercentilesActive={isPercentilesActive}
        isPercentilesLoading={isPercentilesLoading}
        onClickLeaguePercentile={handleToggleLeaguePercentile}
        scrollSizePx={130}
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX('brankáři-tabulka', commonHeader, tableRowCallback, data)
            }
          />
        }
        dataTemplateEntity={IEntity.goalkeepers}
        disablePositionSelection
      />
      <CommonTable<IGoalkeeperStatsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.goalkeepers}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.goaliesData}
          onIceName={ITranslationKeys.teamsData}
          entity={IEntity.goalkeepers}
        />
      )}
    </div>
  );
};
