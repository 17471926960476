import { ITranslationKeys } from '../../i18n/types';
import { ISelectOption } from '../../types';

export const playerEntityOption: ISelectOption = {
  value: 'player',
  label: ITranslationKeys.player,
};

export const teamEntityOption: ISelectOption = {
  value: 'team',
  label: ITranslationKeys.team,
};

export const opponentEntityOption: ISelectOption = {
  value: 'opponent',
  label: ITranslationKeys.opponent,
};

export const entityOptions: ISelectOption[] = [
  playerEntityOption,
  teamEntityOption,
  opponentEntityOption,
];

export const passTypeOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  { value: 'behindTheNet', label: ITranslationKeys.behindTheNet },
  { value: 'crossIce', label: ITranslationKeys.crossIce },
  { value: 'oneTimer', label: ITranslationKeys.passOneTimer },
];

export const minimapComparisonOptions: ISelectOption[] = [
  { value: 'attackType', label: ITranslationKeys.attackType },
  { value: 'shotsDanger', label: ITranslationKeys.shotsDanger },
  { value: 'withTeammate', label: ITranslationKeys.withTeammate },
  { value: 'last5Games', label: ITranslationKeys.last5Games },
];

export const comparisonTypeOptions: ISelectOption[] = [
  { value: 'without', label: ITranslationKeys.withoutComparison },
  { value: 'shotsProduction', label: ITranslationKeys.shotsProduction },
  { value: 'chance', label: ITranslationKeys.useOfOpportunities },
];

export const faceoffResultWonOption: ISelectOption = { value: 'won', label: ITranslationKeys.won };
export const faceoffResultOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all },
  faceoffResultWonOption,
  { value: 'lost', label: ITranslationKeys.lost },
];

export const faceoffsFollowedOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all },
  { value: 'shotAttempt', label: ITranslationKeys.shotAttempt },
  { value: 'shotFromSlot', label: ITranslationKeys.shotFromSlot },
  { value: 'goal', label: ITranslationKeys.goal },
];

export const faceoffOffensiveZoneOption: ISelectOption = {
  value: 'O',
  label: ITranslationKeys.offensiveZone,
};
export const faceoffNeutralZoneOption: ISelectOption = {
  value: 'N',
  label: ITranslationKeys.neutralZone,
};
export const faceoffDefensiveZoneOption: ISelectOption = {
  value: 'D',
  label: ITranslationKeys.defensiveZone,
};
export const faceoffZoneOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all },
  faceoffOffensiveZoneOption,
  faceoffNeutralZoneOption,
  faceoffDefensiveZoneOption,
];
