import { FC } from 'react';

import { ITranslationKeys } from '../../../i18n/types';
import { DownloadIcon, PrintIcon } from '../../../icons';
import { IBasicShotsStats } from '../../../types';
import {
  createClassNames,
  secondsToMinutesAndSeconds,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { IPlaygroundBarBoxes } from '../../types';
import { PlaygroundBarBoxList } from '../PlaygroundBarBoxList';
import { PlaygroundIconWithTooltip } from '../PlaygroundIconWithTooltip';
import './PlaygroundBar.styles.scss';

export interface IPlaygroundBarProps extends IBasicShotsStats {
  /** Time on ice metric */
  toi: number;
  /** Heading for the PlaygroundBoxList component. */
  boxListHeading: ITranslationKeys;
  /** Boxes for playground bar. */
  boxes: IPlaygroundBarBoxes;
  /** When true, the boxes in the PlaygroundComponent are shown. */
  showBoxes: boolean;
  /** Shoold be true when the playground component is reversed. */
  isReversed?: boolean;
  /** Should be true when the playground component is small. */
  isSmall?: boolean;
  /** When true, the playground is responsive. */
  isResponsive?: boolean;
  /** Callback fired when the show boxes icon is clicked. */
  onShowBoxesIconClick?: () => void;
  /** Callback fired when the print icon is clicked. */
  onPrintIconClick?: () => void;
  /** Fired when user clicks on the attributes in boxes. */
  onBoxesClick?: () => void;
}

const classNames = createClassNames('playground-bar');

export const PlaygroundBar: FC<IPlaygroundBarProps> = ({
  toi,
  c,
  xG,
  g,
  boxListHeading,
  boxes,
  showBoxes,
  isReversed = false,
  isSmall = false,
  isResponsive = false,
  onShowBoxesIconClick,
  onPrintIconClick,
  onBoxesClick,
}) => {
  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isReversed,
          showBoxes,
          isResponsive,
        }),
      })}
      data-testid='playground-bar'
    >
      <div className={classNames('box-list')}>
        <PlaygroundBarBoxList
          heading={boxListHeading}
          boxes={boxes}
          isReversed={isReversed}
          isSmall={isSmall}
          onBoxesClick={onBoxesClick}
          isResponsive={true}
        />
      </div>
      <div className={classNames('stats')}>
        <span>TOI: {secondsToMinutesAndSeconds(toi)}</span>
        <span>C: {c}</span>
        <span>xG: {xG.toFixed(2)}</span>
        <span>G: {g}</span>
      </div>
      <div className={classNames('icons')}>
        <PlaygroundIconWithTooltip
          label={showBoxes ? ITranslationKeys.hide : ITranslationKeys.shotsDanger}
          onIconClick={onShowBoxesIconClick}
          icon={<DownloadIcon />}
          rotateIcon={isReversed ? showBoxes : !showBoxes}
          isClickable
        />
        {!isSmall && (
          <PlaygroundIconWithTooltip
            label={ITranslationKeys.saveMap}
            icon={<PrintIcon />}
            showDownloadIcon={true}
            onIconClick={onPrintIconClick}
            isClickable
          />
        )}
      </div>
    </div>
  );
};
