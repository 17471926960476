import { ITranslationKeys } from '../../i18n/types';
import { IRole, ISelectOption } from '../../types';

export const placeOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.homeAndAway },
  { value: 'home', label: ITranslationKeys.home },
  { value: 'away', label: ITranslationKeys.away },
];

export const scoreStateOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.allGameStates },
  { value: 'lead', label: ITranslationKeys.leading },
  { value: 'trail', label: ITranslationKeys.losing },
  { value: 'tie', label: ITranslationKeys.tied },
];

export const positionOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.allPositions },
  { value: 'FO', label: ITranslationKeys.onlyForwards },
  { value: 'DE', label: ITranslationKeys.onlyDefensemen },
];

export const stickGripBaseOptions: ISelectOption[] = [
  { value: 'left', label: ITranslationKeys.leftStick },
  { value: 'right', label: ITranslationKeys.rightStick },
];

export const stickGripOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.leftAndRightStick },
  ...stickGripBaseOptions,
];

export const stickGripToggleOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  ...stickGripBaseOptions,
];

export const defaultTemplate: ISelectOption = {
  value: 'default',
  label: ITranslationKeys.defaultTemplate,
  isAllOption: true,
};

export const teamAllOption: ISelectOption = {
  value: 'all',
  label: ITranslationKeys.allTeams,
  isAllOption: true,
};

export const notFilledOutOption: ISelectOption = {
  value: '',
  label: ITranslationKeys.notFilledOut,
  isAllOption: true,
};

export const goalkeeperSavesOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  { value: 'controlled', label: ITranslationKeys.controlledSaves },
  { value: 'uncontrolled', label: ITranslationKeys.uncontrolled },
];

export const playersInChartOptions: ISelectOption[] = [
  { value: 'teammate', label: ITranslationKeys.teammates },
  { value: 'opponent', label: ITranslationKeys.opponents },
];

export const gamePartDefaultOption: ISelectOption = {
  value: 'all',
  label: ITranslationKeys.all,
  labelTranslationsOptions: { context: 'female' },
};
export const gamePartOptions: ISelectOption[] = [
  gamePartDefaultOption,
  {
    value: '1',
    label: ITranslationKeys.periodWithOrder,
    labelTranslationsOptions: { count: 1 },
  },
  {
    value: '2',
    label: ITranslationKeys.periodWithOrder,
    labelTranslationsOptions: { count: 2 },
  },
  {
    value: '3',
    label: ITranslationKeys.periodWithOrder,
    labelTranslationsOptions: { count: 3 },
  },
  { value: '4', label: ITranslationKeys.overtime },
];

export const baseRoleOptions: ISelectOption[] = [
  { value: IRole.media, label: ITranslationKeys.media },
  { value: IRole.user, label: ITranslationKeys.user },
  { value: IRole.team, label: ITranslationKeys.team },
];

export const baseRoleOptionsWithAll: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  ...baseRoleOptions,
];

export const roleOptions: ISelectOption[] = [
  { value: IRole.admin, label: ITranslationKeys.admin },
  ...baseRoleOptions,
  { value: IRole.client, label: ITranslationKeys.client },
  { value: IRole.test, label: 'Test' },
];

export const roleOptionsWithAll: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  ...roleOptions,
];

export const shiftEventsOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  { value: 'player', label: ITranslationKeys.player },
  { value: 'team', label: ITranslationKeys.team },
  { value: 'opponent', label: ITranslationKeys.opponent },
];

export const trendTimePeriodCustomOption: ISelectOption = {
  value: 'custom',
  label: ITranslationKeys.custom,
};

export const trendTimePeriodTypeOptions: ISelectOption[] = [
  { value: '5games', label: ITranslationKeys.nGames, labelTranslationsOptions: { count: 5 } },
  { value: '10games', label: ITranslationKeys.nGames, labelTranslationsOptions: { count: 10 } },
  { value: 'months', label: ITranslationKeys.months },
  { value: 'quarter', label: ITranslationKeys.quarter },
  trendTimePeriodCustomOption,
];
