import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../app/hooks';
import { closeVideoCenter } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { CloseBlueIcon, DownloadIcon } from '../../../icons';
import { INavigationPageKeys } from '../../../types';
import { createClassNames } from '../../../utils';
import './VideoCenterHeader.styles.scss';

interface IVideoCenterHeader {
  /** Function that handles modal close */
  onClose: () => void;
}

const classNames = createClassNames('video-center-header');

/** VideoCenterHeader UI component. */
export const VideoCenterHeader: FC<IVideoCenterHeader> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleDownloadManagement = () => {
    dispatch(closeVideoCenter());
    const urlToOpen = `/${INavigationPageKeys.accountManagement}`;
    window.open(urlToOpen, '_blank');
  };

  return (
    <div className={classNames()}>
      <h1 className={classNames('title')}>{t(ITranslationKeys.videoCenter)}</h1>
      <div className={classNames('actions-container')}>
        <div className={classNames('action')} onClick={handleDownloadManagement}>
          <DownloadIcon />
          <p>{t(ITranslationKeys.downloadsManagement)}</p>
        </div>
        <div className={classNames('action')} onClick={onClose}>
          <CloseBlueIcon />
          <p>{t(ITranslationKeys.close)}</p>
        </div>
      </div>
    </div>
  );
};
