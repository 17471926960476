import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { baseRoleOptionsWithAll } from '../../../constants';
import { ITranslationKeys } from '../../../i18n/types';
import { ITrackingUserFilterForm } from '../../../types';
import { createClassNames, isFormValid } from '../../../utils';
import { TcmsButton } from '../TcmsButton';
import { RoleController, UserController } from '../TcmsControllers';
import './TrackingUserFilter.styles.scss';

interface ITrackingUserFilterProps {
  onSubmit: () => void;
}

const classNames = createClassNames('tracking-user-filter');

export const TrackingUserFilter: FC<ITrackingUserFilterProps> = ({ onSubmit }) => {
  const { control, formState } = useFormContext<ITrackingUserFilterForm>();
  const { isDirty, errors } = formState;

  return (
    <div className={classNames()}>
      <form noValidate>
        <UserController name='userFilterText' control={control} />
        <RoleController name='selectedRole' control={control} options={baseRoleOptionsWithAll} />
        <TcmsButton
          variant='filled'
          color='blue'
          label={ITranslationKeys.displayData}
          width={216}
          onClick={onSubmit}
          disabled={!isDirty || !isFormValid(errors)}
        />
      </form>
    </div>
  );
};
