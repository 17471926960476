import { ITranslationKeys } from '../../i18n/types';
import { ISelectOption } from '../../types';

export const shootoutEndResultGoalOption: ISelectOption = {
  value: 'goal',
  label: ITranslationKeys.goals,
};
export const shootoutEndResultOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.allAttempts },
  shootoutEndResultGoalOption,
];

export const shootoutEndTypeShotOption: ISelectOption = {
  value: 'shot',
  label: ITranslationKeys.shot,
};
export const shootoutEndTypeFeintOption: ISelectOption = {
  value: 'feint',
  label: ITranslationKeys.feint,
};
export const shootoutEndTypeOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  shootoutEndTypeShotOption,
  shootoutEndTypeFeintOption,
];

export const shotoutEndSideOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  { value: 'forehand', label: ITranslationKeys.forehand },
  { value: 'backhand', label: ITranslationKeys.backhand },
];

export const shootoutStickGripOptions: ISelectOption[] = [
  { value: 'all', label: ITranslationKeys.all, labelTranslationsOptions: { context: 'female' } },
  { value: 'right', label: ITranslationKeys.rightStick },
  { value: 'left', label: ITranslationKeys.leftStick },
];
