import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  ImageColumn,
  PlayerColumnCell,
  VideoCenterColumnCell,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IColumnsConfig,
  IGamesTablesPlayerTableData,
  INavigationContentKeys,
  INavigationPageKeys,
  IUrlFilterParams,
} from '../../../../types';
import { createDynamicStatsColumnsDef, secondsToMinutesAndSeconds } from '../../../../utils';

const columnHelper = createColumnHelper<IGamesTablesPlayerTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'teamId', 'player', 'toi'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IGamesTablesPlayerTableData, any>[] = [
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('player', {
    header: () => '',
    cell: props => <PlayerColumnCell {...props} />,
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
  onToiClick: (playerId: string) => void,
): IColumnsConfig<IGamesTablesPlayerTableData> => {
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      undefined,
      centerPartWidth,
    );
    const toiColumn: ColumnDef<IGamesTablesPlayerTableData, any> = columnHelper.accessor('toi', {
      header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
      cell: props => (
        <BasicColumnCell {...props}>
          <VideoCenterColumnCell
            {...props}
            onClick={() => onToiClick(props.row.original.player.id)}
          >
            {secondsToMinutesAndSeconds(props.getValue())}
          </VideoCenterColumnCell>
        </BasicColumnCell>
      ),
    });
    const actionsColumn = createActionsColumn();

    return [actionsColumn].concat(fixedColumns).concat(toiColumn).concat(dynamicColumns);
    // eslint-disable-next-line
  }, [centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createActionsColumn = () =>
  columnHelper.display({
    id: 'actions',
    cell: props => {
      const filterParams: IUrlFilterParams[] = [
        {
          name: 'selectedTeam',
          value: props.row.original.teamId,
        },
        {
          name: 'selectedPlayerItems',
          value: props.row.original.player.id,
        },
      ];

      return (
        <EyeDropdown
          eyeDropdownTooltipItems={[
            {
              pageTranslationLabel: ITranslationKeys.gamelog,
              navigationPageKey: INavigationPageKeys.players,
              navigationTabKey: INavigationContentKeys.gamelog,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.trend,
              navigationPageKey: INavigationPageKeys.players,
              navigationTabKey: INavigationContentKeys.trend,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.combinations,
              navigationPageKey: INavigationPageKeys.formations,
              navigationTabKey: INavigationContentKeys.playerCombinations,
              filterParams,
            },
          ]}
        />
      );
    },
  });
