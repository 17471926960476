import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { filteredGamesTablesDataSelector, selectGames, selectPlayers } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IGamesTablesPlayerTableData,
  IMetricWithDatasetMetric,
  IPlayerRecord,
  IPlayerWithTeamIdAndStats,
  ISimilarPlayerNameRecord,
} from '../../../../types';
import {
  filteredPlayersOfGameTablesByTeamId,
  getMetricsWithValue,
  getPlayerShortName,
} from '../../../../utils';

const getTableData = (
  playersWithStatsFiltered: IPlayerWithTeamIdAndStats[],
  playerRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  similarPlayerNames: ISimilarPlayerNameRecord,
) => {
  const tableData = playersWithStatsFiltered
    .reduce<IGamesTablesPlayerTableData[]>((acc, playersWithTeamIdAndStats) => {
      const player = playerRecord[playersWithTeamIdAndStats.id];
      const stats = getMetricsWithValue(metrics, playersWithTeamIdAndStats.stats);

      if (player) {
        acc.push({
          entityId: player.id,
          teamId: playersWithTeamIdAndStats.teamId,
          player: {
            id: player.id,
            displayName: getPlayerShortName(player, similarPlayerNames),
          },
          gp: playersWithTeamIdAndStats.stats.gp,
          toi: playersWithTeamIdAndStats.stats.toi,
          stats,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = (selectedFilterTeamId?: string) => {
  const { gamesTables } = useAppSelector(selectGames);
  const { playersById } = gamesTables;
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const filteredGamesTablesData = useAppSelector(state =>
    filteredGamesTablesDataSelector(state, playersById),
  );

  const getFilteredMetrics = useFilteredMetricsForTable();

  const playersWithTeamIdAndStatsFiltered = useMemo(
    () =>
      filteredPlayersOfGameTablesByTeamId(
        filteredGamesTablesData.filter(player => player.id),
        selectedFilterTeamId,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playersById, filteredGamesTablesData, selectedFilterTeamId],
  );

  const data: IGamesTablesPlayerTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      return getTableData(
        playersWithTeamIdAndStatsFiltered,
        byId,
        filteredMetrics,
        similarPlayerNames,
      );
    }

    return [];
  }, [playersWithTeamIdAndStatsFiltered, byId, similarPlayerNames, getFilteredMetrics]);

  return data;
};
