import { DAYS_DIFFERENCE_BY_INTERVAL } from '../../constants';
import {
  IBaseIdentity,
  ICmsUserFilterForm,
  IDateRangeString,
  IIdentity,
  ILogHokejPlayer,
  IRole,
  IRoleMediaTeam,
  ISelectOption,
  ITeamRecord,
  ITrackingTimeInterval,
} from '../../types';
import { createDateFromTo, formatDateByLanguage, parseDateToString } from '../date.utils';

const filterIdentitiesByText = (identities: IIdentity[], text: string) => {
  if (!text.trim()) return identities;
  const lowercasedText = text.toLowerCase();

  return identities.filter(
    identity =>
      identity.name?.toLowerCase().includes(lowercasedText) ||
      identity.surname?.toLowerCase().includes(lowercasedText) ||
      identity.username?.toLowerCase().includes(lowercasedText),
  );
};

const filterIdentitiesByRole = (identities: IIdentity[], role?: ISelectOption) => {
  if (!role || role.value === 'all') return identities;

  const roleValue = role.value;
  if (roleValue === IRole.user) {
    return identities.filter(identity => identity.role === IRole.user && !identity.teamId);
  }

  return identities.filter(identity => identity.role === roleValue);
};

const filterIdentitiesByTeamOrMedia = (
  identities: IIdentity[],
  teamOrMedia: ISelectOption[],
  teamRecord: ITeamRecord,
  selectedRole?: ISelectOption,
) => {
  if (teamOrMedia.some(entity => entity.value === 'all')) return identities;

  if (selectedRole?.value === IRole.media) {
    return identities.filter(identity =>
      teamOrMedia.some(
        entity => entity.value.toLocaleLowerCase() === identity.media?.toLocaleLowerCase(),
      ),
    );
  }

  return identities.filter(identity => {
    if (!identity.teamId) return false;

    const team = teamRecord[identity.teamId];
    const value = team ? team.shortcut.toLowerCase() : identity.teamId;
    return teamOrMedia.some(option => option.value.toLocaleLowerCase() === value);
  });
};

export const filterIdentitiesByFilter = (
  identities: IIdentity[],
  filter: ICmsUserFilterForm,
  teamRecord: ITeamRecord,
) => {
  const { userFilterText, selectedRole, selectedTeamOrMedia } = filter;
  let filteredIdentities = filterIdentitiesByText(identities, userFilterText);
  filteredIdentities = filterIdentitiesByRole(filteredIdentities, selectedRole);
  filteredIdentities = filterIdentitiesByTeamOrMedia(
    filteredIdentities,
    selectedTeamOrMedia,
    teamRecord,
    selectedRole,
  );

  return filteredIdentities;
};

export const formatAccessAtToDate = (accessAt: string) => {
  const date = new Date(accessAt);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${formatDateByLanguage(date, 'P')} | ${hours}:${minutes}`;
};

export const getLogHokejPlayerTotals = (data: ILogHokejPlayer[]) =>
  data.reduce(
    (acc, { notificationCount, openedNotificationCount }) => ({
      notificationCount: acc.notificationCount + notificationCount,
      openedNotificationCount: acc.openedNotificationCount + openedNotificationCount,
    }),
    { notificationCount: 0, openedNotificationCount: 0 },
  );

export const filterEnabledRoles = <T extends IRoleMediaTeam | IBaseIdentity>(item: T) =>
  item.role !== IRole.admin &&
  item.role !== IRole.client &&
  item.role !== IRole.test &&
  item.media !== 'BPA';

export const teamOrMediaIdByRole = (
  role: IRole,
  teamId: string | null,
  media: string | null,
  teamRecord: ITeamRecord,
  mediaOptions: ISelectOption[],
): string | undefined => {
  if (role === IRole.user || role === IRole.client) return undefined;

  const userMedia = media ? mediaOptions.find(option => option.value === media)?.label : undefined;
  const usersTeam = teamId ? teamRecord[teamId]?.name : undefined;

  if (role === IRole.admin || role === IRole.test) return usersTeam ?? userMedia;
  if (role === IRole.media) return userMedia;
  if (role === IRole.team) return usersTeam;

  return undefined;
};

export const teamAndMediaOptionsByRole = (allOptions: ISelectOption[], role: IRole) => {
  if (role === IRole.admin || role === IRole.test) {
    return allOptions;
  }

  if (role === IRole.media) {
    return allOptions.filter(
      option => option.additionalValue === IRole.media || option.value === '',
    );
  }

  if (role === IRole.team) {
    return allOptions.filter(option => option.additionalValue !== IRole.media);
  }

  return [];
};

export const teamOrMediaTypeByRole = (option: ISelectOption, role: IRole) => {
  if (role === IRole.admin || role === IRole.test) {
    return option.additionalValue === 'media' ? 'media' : 'team';
  }
  if (role === IRole.media) return 'media';
  if (role === IRole.team) return 'team';

  return 'team';
};

/**
 * Returns correct date format by time interval.
 * Common date for day and week. Month format is (m/yyyy) and season format is (yyyy/yyyy+1).
 * @param dateString Date as string.
 * @param timeInterval Time interval value (D, W, M, S)
 * @returns Correct date format by time interval.
 */
export const formatDateByTimeInterval = (dateString: string, timeInterval?: string) => {
  const date = new Date(dateString);

  if (!timeInterval) {
    return formatDateByLanguage(date, 'P');
  }

  if (timeInterval === 'M') {
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  if (timeInterval === 'S') {
    return `${date.getFullYear()}/${date.getFullYear() + 1}`;
  }

  return formatDateByLanguage(date, 'P');
};

/**
 * Returns next page date range string.
 * @param page Page number.
 * @param dateString Last submitted date string.
 * @param timeInterval Time interval value (D, W, M, S)
 * @returns Next page date range.
 */
export const getNextPageDateRangeString = (
  page: number,
  submittedDateString: string | undefined,
  timeInterval: ITrackingTimeInterval,
): IDateRangeString => {
  const daysDifference = DAYS_DIFFERENCE_BY_INTERVAL[timeInterval];

  if (timeInterval === 'S') {
    return createDateFromTo(parseDateToString(new Date()), daysDifference);
  }

  if (page < 1 || !submittedDateString) {
    return createDateFromTo(submittedDateString ?? parseDateToString(new Date()), daysDifference);
  }

  const helperDate = new Date(submittedDateString);
  const nextDate = new Date(
    helperDate.setDate(helperDate.getDate() + page * daysDifference),
  ).toISOString();

  return createDateFromTo(nextDate, daysDifference);
};

export const parseUrlUsername = (
  searchParams: URLSearchParams,
  identities: IIdentity[],
): string | undefined => {
  const username = searchParams.get('username');

  if (!username) return undefined;

  return identities.find(identity => identity.username === username)?.username;
};
