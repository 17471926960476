import {
  allTeamOrMediaAllOption,
  analyticsTypeDefaultOption,
  analyticsTypeScreenDefaultOption,
  attendanceDefaultOption,
  screenLogKeysMap,
  teamAllOption,
  trackingRoleOptions,
} from '../../constants';
import {
  IAccessLogAggregationEntityRecord,
  IHokejPlayerTrackingFilterForm,
  ILogHokejPlayer,
  IMainTrackingFilterForm,
  IRoleLogAggregationDate,
  IRoleLogAggregationDateRecord,
  IScreenLogTabRecord,
  ISubTab,
  ISubTabRecord,
  ITab,
  ITrackingUserType,
} from '../../types';
import { createDateFromTo } from '../date.utils';
import { createSeasonDateRangeFromDate } from '../seasons.utils';

export const filterRoleLogByRole = (
  data: IRoleLogAggregationDateRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  const { selectedRole } = submitValues;

  if (selectedRole?.value === 'all' || !data || !selectedRole) return data;

  return Object.values(data)
    .map<IRoleLogAggregationDate>(item => {
      const newItem: IRoleLogAggregationDate = {
        date: item.date,
        media: [],
        team: [],
        other: [],
      };

      if (selectedRole.value === ITrackingUserType.media) {
        newItem.media.push(...item.media);
        return newItem;
      }

      if (selectedRole.value === ITrackingUserType.team) {
        newItem.team.push(...item.team);
        return newItem;
      }

      newItem.other.push(...item.other);
      return newItem;
    })
    .filter(item => item.media.length > 0 || item.team.length > 0 || item.other.length > 0)
    .reduce<IRoleLogAggregationDateRecord>(
      (acc, item) => ({
        ...acc,
        [item.date]: item,
      }),
      {},
    );
};

export const filterRoleLogByTeamOrMedia = (
  data: IRoleLogAggregationDateRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  const { selectedRole, selectedTeamOrMedia } = submitValues;

  if (selectedTeamOrMedia.length === 0) return data;

  const allData = selectedTeamOrMedia.some(item => item.value === 'all');

  if (allData || !data || !selectedRole) return data;

  const filteredData =
    selectedRole.value === ITrackingUserType.media
      ? Object.values(data).map<IRoleLogAggregationDate>(item => {
          const filteredMedia = item.media.filter(media =>
            selectedTeamOrMedia.some(option => option.value === media.name),
          );

          return {
            date: item.date,
            media: filteredMedia,
            team: [],
            other: [],
          };
        })
      : Object.values(data).map<IRoleLogAggregationDate>(item => {
          const filteredTeams = item.team.filter(team =>
            selectedTeamOrMedia.some(option => option.value === team.name),
          );

          return {
            date: item.date,
            media: [],
            team: filteredTeams,
            other: [],
          };
        });

  return filteredData
    .filter(item => item.media.length > 0 || item.team.length > 0 || item.other.length > 0)
    .reduce<IRoleLogAggregationDateRecord>(
      (acc, item) => ({
        ...acc,
        [item.date]: item,
      }),
      {},
    );
};

export const filterRoleLogData = (
  data: IRoleLogAggregationDateRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  const byRole = filterRoleLogByRole(data, submitValues);
  const byAll = filterRoleLogByTeamOrMedia(byRole, submitValues);

  return byAll;
};

export const filterAccessLogByRole = (
  data: IAccessLogAggregationEntityRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  if (!submitValues.selectedRole || submitValues.selectedRole.value === 'all' || !data) return data;

  return Object.values(data)
    .filter(item => item.type === submitValues.selectedRole?.value)
    .reduce<IAccessLogAggregationEntityRecord>(
      (acc, item) => ({
        ...acc,
        [item.id]: item,
      }),
      {},
    );
};

export const filterAccessLogByTeamOrMedia = (
  data: IAccessLogAggregationEntityRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  if (submitValues.selectedTeamOrMedia.length > 0) {
    const allData = submitValues.selectedTeamOrMedia.some(item => item.value === 'all');

    if (allData || !data) return data;

    const filteredData = submitValues.selectedTeamOrMedia.reduce<IAccessLogAggregationEntityRecord>(
      (acc, option) => {
        const filteredData = Object.values(data)
          .filter(item => item.id === option.value)
          .reduce<IAccessLogAggregationEntityRecord>(
            (acc, item) => ({
              ...acc,
              [item.id]: item,
            }),
            {},
          );

        return { ...acc, ...filteredData };
      },
      {},
    );

    return filteredData;
  }

  return data;
};

export const filterAccessLogData = (
  data: IAccessLogAggregationEntityRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  const byRole = filterAccessLogByRole(data, submitValues);
  const byAll = filterAccessLogByTeamOrMedia(byRole, submitValues);

  return byAll;
};

export const filterScreenLogByRole = (
  data: IScreenLogTabRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  const { selectedRole } = submitValues;

  if (selectedRole?.value === 'all' || !data || !selectedRole) return data;

  return Object.values(data)
    .map<ITab>(item => {
      const filteredSubTabRecord = Object.values(item.subTabs)
        .map<ISubTab>(subTab => {
          const newSubItem: ISubTab = {
            name: subTab.name,
            aggregation: {
              [ITrackingUserType.team]: [],
              [ITrackingUserType.media]: [],
              [ITrackingUserType.other]: [],
            },
          };

          if (selectedRole.value === ITrackingUserType.media) {
            newSubItem.aggregation[ITrackingUserType.media].push(...subTab.aggregation.media);
            return newSubItem;
          }

          if (selectedRole.value === ITrackingUserType.team) {
            newSubItem.aggregation[ITrackingUserType.team].push(...subTab.aggregation.team);
            return newSubItem;
          }

          newSubItem.aggregation[ITrackingUserType.other].push(...subTab.aggregation.other);
          return newSubItem;
        })
        .filter(
          item =>
            item.aggregation.media.length > 0 ||
            item.aggregation.team.length > 0 ||
            item.aggregation.other.length > 0,
        )
        .reduce<ISubTabRecord>(
          (acc, item) => ({
            ...acc,
            [item.name]: item,
          }),
          {},
        );

      return {
        name: item.name,
        subTabs: filteredSubTabRecord,
      };
    })
    .reduce<IScreenLogTabRecord>(
      (acc, item) => ({
        ...acc,
        [item.name]: item,
      }),
      {},
    );
};

export const filterScreenLogByTeamOrMedia = (
  data: IScreenLogTabRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  const { selectedRole, selectedTeamOrMedia } = submitValues;

  if (selectedTeamOrMedia.length === 0) return data;

  const allData = selectedTeamOrMedia.some(item => item.value === 'all');

  if (allData || !data || !selectedRole) return data;

  const filteredData =
    selectedRole.value === ITrackingUserType.media
      ? Object.values(data).map<ITab>(item => {
          const filteredSubTabRecord = Object.values(item.subTabs)
            .map<ISubTab>(subTab => {
              const filteredMedia = subTab.aggregation.media.filter(screenLog =>
                selectedTeamOrMedia.some(option => option.value === screenLog.media),
              );

              return {
                name: subTab.name,
                aggregation: {
                  [ITrackingUserType.team]: [],
                  [ITrackingUserType.media]: filteredMedia,
                  [ITrackingUserType.other]: [],
                },
              };
            })
            .filter(
              item =>
                item.aggregation.media.length > 0 ||
                item.aggregation.team.length > 0 ||
                item.aggregation.other.length > 0,
            )
            .reduce<ISubTabRecord>(
              (acc, item) => ({
                ...acc,
                [item.name]: item,
              }),
              {},
            );

          return {
            name: item.name,
            subTabs: filteredSubTabRecord,
          };
        })
      : Object.values(data).map<ITab>(item => {
          const filteredSubTabRecord = Object.values(item.subTabs)
            .map<ISubTab>(subTab => {
              const filteredTeam = subTab.aggregation.team.filter(screenLog =>
                selectedTeamOrMedia.some(option => option.value === screenLog.team),
              );

              return {
                name: subTab.name,
                aggregation: {
                  [ITrackingUserType.team]: filteredTeam,
                  [ITrackingUserType.media]: [],
                  [ITrackingUserType.other]: [],
                },
              };
            })
            .filter(
              item =>
                item.aggregation.media.length > 0 ||
                item.aggregation.team.length > 0 ||
                item.aggregation.other.length > 0,
            )
            .reduce<ISubTabRecord>(
              (acc, item) => ({
                ...acc,
                [item.name]: item,
              }),
              {},
            );

          return {
            name: item.name,
            subTabs: filteredSubTabRecord,
          };
        });

  return filteredData.reduce<IScreenLogTabRecord>(
    (acc, item) => ({
      ...acc,
      [item.name]: item,
    }),
    {},
  );
};

export const filterScreenLogData = (
  data: IScreenLogTabRecord | undefined,
  submitValues: IMainTrackingFilterForm,
) => {
  const byRole = filterScreenLogByRole(data, submitValues);
  const byAll = filterScreenLogByTeamOrMedia(byRole, submitValues);

  return byAll;
};

export const getDefaultMainTrackingFormValues = (
  isScreen: boolean = false,
): IMainTrackingFilterForm => ({
  selectedRole: trackingRoleOptions.find(option => option.value === 'all'),
  selectedTeamOrMedia: [allTeamOrMediaAllOption],
  selectedAnalytics: isScreen ? analyticsTypeScreenDefaultOption : analyticsTypeDefaultOption,
  dateFromTo: isScreen ? createSeasonDateRangeFromDate(new Date()) : createDateFromTo(),
});

export const getDefaultHokejPlayerTrackingFormValues = (): IHokejPlayerTrackingFilterForm => ({
  teamSelected: teamAllOption,
  searchedPlayer: undefined,
  attendance: attendanceDefaultOption,
  dateFromTo: createSeasonDateRangeFromDate(new Date()),
});

export const filterLogHokejPlayerData = (
  data: ILogHokejPlayer[] | undefined,
  submitValues: IHokejPlayerTrackingFilterForm,
) => {
  if (!data) return [];
  if (submitValues.teamSelected.value === 'all') return data;

  const byTeam = data.filter(item => item.team === submitValues.teamSelected.value);
  return byTeam;
};

export const normalizeSelectedEventParam = (value: string) => {
  const entries = Array.from(screenLogKeysMap.entries());

  const keyValue = entries.find(([_, val]) => val === value);

  return keyValue ? keyValue[0] : 'none';
};
