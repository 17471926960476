import { IGamelog, IGamelogDTO } from '../../types';

export const parseGamelogData = (data: IGamelogDTO[]): IGamelog[] => {
  const gamelog: IGamelog[] = data.map(gamelog => ({
    id: gamelog.uuid,
    stats: gamelog.stats,
    info: {
      awayTeamId: gamelog.info.awayTeamUuid,
      homeTeamId: gamelog.info.homeTeamUuid,
      date: gamelog.info.date,
      score: gamelog.info.score,
    },
  }));

  return gamelog;
};
