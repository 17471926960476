import { ITranslationKeys } from '../i18n/types';
import {
  IAccessLogFilterForm,
  ICmsUserFilterForm,
  IEventScreenLogFilterForm,
  ISelectOption,
  ITrackingTimeInterval,
  ITrackingUserFilterForm,
} from '../types';
import { eventLogEventOptions, roleOptionsWithAll } from './selectOptionsConstants';

export const DEFAULT_TCMS_TABLE_PAGE_ITEMS_COUNT = 15;

export const allTeamOrMediaAllOption: ISelectOption = {
  value: 'all',
  label: ITranslationKeys.all,
  labelTranslationsOptions: { context: 'male' },
  isAllOption: true,
};

/** CMS user filter form default values. */
export const DEFAULT_CMS_USER_FILTER_FORM_VALUES: ICmsUserFilterForm = {
  userFilterText: '',
  selectedRole: roleOptionsWithAll.find(option => option.value === 'all'),
  selectedTeamOrMedia: [allTeamOrMediaAllOption],
};

/** Access log (login page) filter form default values. */
export const DEFAULT_ACCESS_LOG_FILTER_FORM_VALUES: IAccessLogFilterForm = {
  selectedRole: roleOptionsWithAll.find(option => option.value === 'all'),
  selectedTeamOrMedia: [allTeamOrMediaAllOption],
};

/** Event (Screen) log (event page) filter form default values. */
export const DEFAULT_EVENT_SCREEN_LOG_FILTER_FORM_VALUES: IEventScreenLogFilterForm = {
  selectedRole: roleOptionsWithAll.find(option => option.value === 'all'),
  selectedEvent: eventLogEventOptions.find(option => option.value === 'all'),
  selectedTeamOrMedia: [allTeamOrMediaAllOption],
};

/** Tracking user log (users page) filter form default values. */
export const DEFAULT_USER_LOG_FILTER_FORM_VALUES: ITrackingUserFilterForm = {
  selectedRole: roleOptionsWithAll.find(option => option.value === 'all'),
  userFilterText: '',
};

export const ACTUAL_SEASON_TEAMS_TCMS = [
  'all',
  'MBL',
  'LIB',
  'PCE',
  'KVA',
  'KOM',
  'CEB',
  'TRI',
  'OLO',
  'SPA',
  'LIT',
  'VIT',
  'PLZ',
  'MHK',
  'KLA',
];

export const ACTUAL_SEASON_TEAMS_TCMS_OPTIONS = ACTUAL_SEASON_TEAMS_TCMS.map<ISelectOption>(
  team => ({
    value: team,
    label: team,
  }),
);

const PRELOAD_MULTIPLIER = -3;
const SIZE_OF_DISPLAYED_DATA = 7;
const DAYS_DIFFERENCE_D = SIZE_OF_DISPLAYED_DATA * PRELOAD_MULTIPLIER;
const DAYS_DIFFERENCE_W = 7 * SIZE_OF_DISPLAYED_DATA * PRELOAD_MULTIPLIER;
const DAYS_DIFFERENCE_M = 30 * SIZE_OF_DISPLAYED_DATA * PRELOAD_MULTIPLIER;
const DAYS_DIFFERENCE_S = 365 * SIZE_OF_DISPLAYED_DATA * -2;
export const DAYS_DIFFERENCE_BY_INTERVAL: Record<ITrackingTimeInterval, number> = {
  D: DAYS_DIFFERENCE_D,
  W: DAYS_DIFFERENCE_W,
  M: DAYS_DIFFERENCE_M,
  S: DAYS_DIFFERENCE_S,
};

export const screenLogKeysMap = new Map<string, string>([
  ['matches', 'games'],
  ['overview', 'overview'],
  ['shots', 'shots'],
  ['formations', 'formationsCompare'],
  ['goalkeepers', 'goalkeepers'],
  ['faceOff', 'faceoffs'],
  ['iceTime', 'iceTime'],
  ['h2h', 'headToHead'],
  ['momentum', 'momentum'],
]);
