import { Dispatch, FC, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useAppSelector } from '../../../app/hooks';
import {
  allTeamOrMediaAllOption,
  analyticsTypeOptions,
  analyticsTypeScreenOptions,
  trackingRoleOptions,
} from '../../../constants';
import { selectSeasons, teamAndMediaOptionsSelector } from '../../../features';
import { ITranslationKeys } from '../../../i18n/types';
import { UserRoleIcon } from '../../../icons';
import {
  IMainTrackingFilterForm,
  IMultiParamsVoid,
  ISelectOption,
  ITrackingUserType,
} from '../../../types';
import { createClassNames, createDateRangeFromSeasonYear, isFormValid } from '../../../utils';
import { Caption } from '../../Caption';
import { SelectInput } from '../../SelectInput';
import { SeasonSelect } from '../SeasonSelect';
import { TcmsButton } from '../TcmsButton';
import {
  AnalyticsTypeController,
  DateRangeController,
  SelectTeamOrMediaController,
} from '../TcmsControllers';
import './MainTrackingFilter.styles.scss';

interface IMainTrackingFilterProps {
  /** On data submit callback. */
  onSubmit: () => void;
  /** Is component at page that displays screen log stats? */
  isScreen?: boolean;
}

const classNames = createClassNames('main-tracking-filter');

export const MainTrackingFilter: FC<IMainTrackingFilterProps> = ({ onSubmit, isScreen }) => {
  const teamAndMediaOptions = useAppSelector(teamAndMediaOptionsSelector);
  const seasons = useAppSelector(selectSeasons);

  const { control, formState, watch, setValue } = useFormContext<IMainTrackingFilterForm>();
  const { isDirty, errors } = formState;

  const selectedRole = watch('selectedRole');
  const filteredTeamOrMediaOptions = teamAndMediaOptions.filter(
    option => option.additionalValue === selectedRole?.value || option.value === 'all',
  );

  const handleOnRoleChange = (value: ISelectOption, onChange: IMultiParamsVoid) => {
    onChange(value);
    setValue('selectedTeamOrMedia', [allTeamOrMediaAllOption], {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectSeasonDateRange = (
    season: ISelectOption,
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => {
    const seasonDateRange = createDateRangeFromSeasonYear(season.value);

    setValue('dateFromTo', seasonDateRange, {
      shouldDirty: true,
      shouldValidate: true,
    });

    setOpen(false);
  };

  return (
    <div className={classNames()}>
      <div>
        <Caption label={ITranslationKeys.userRole} variant='tcms' color='slate' />
        <Controller
          name='selectedRole'
          control={control}
          render={({ field: { value, onChange } }) => (
            <SelectInput
              onChange={newValue => handleOnRoleChange(newValue, onChange)}
              selected={value}
              options={trackingRoleOptions}
              placeholder={ITranslationKeys.defaultSelectPlaceholder}
              variant='tcms'
              iconComponent={<UserRoleIcon />}
            />
          )}
        />
      </div>
      <SelectTeamOrMediaController
        name='selectedTeamOrMedia'
        control={control}
        options={filteredTeamOrMediaOptions}
        disabled={selectedRole?.value === 'all' || selectedRole?.value === ITrackingUserType.other}
      />
      <AnalyticsTypeController
        name='selectedAnalytics'
        control={control}
        options={isScreen ? analyticsTypeScreenOptions : analyticsTypeOptions}
      />
      <DateRangeController
        name='dateFromTo'
        control={control}
        renderExtraControlElement={setOpen => (
          <SeasonSelect
            seasonRecord={seasons.byId}
            width='280px'
            onSeasonClick={season => handleSelectSeasonDateRange(season, setOpen)}
          />
        )}
      />
      <TcmsButton
        variant='filled'
        color='blue'
        label={ITranslationKeys.displayData}
        width={216}
        onClick={onSubmit}
        disabled={!isDirty || !isFormValid(errors)}
      />
    </div>
  );
};
