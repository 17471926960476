import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  CommonTable,
  DataSettingsModal,
  Loading,
  TableToolbar,
} from '../../../../components';
import {
  selectDataSettingsFilter,
  selectIndividualStats,
  selectMetrics,
  selectPlayers,
  selectRanks,
  selectTeams,
  setIsPercentilesActive,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchIndividualStats,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { IExportRows, IIndividualStatsTableData } from '../../../../types';
import {
  createClassNames,
  getPlayerPositionShortcut,
  getPlayerStick,
  handleExportToXLSX,
  roundNumberTo2Decimals,
} from '../../../../utils';
import './TabsContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

const classNames = createClassNames('players-tabs-content');

export const TabsContent = () => {
  const { isLoading, isPercentilesActive, isPercentilesLoading } =
    useAppSelector(selectIndividualStats);
  const ranks = useAppSelector(selectRanks);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const players = useAppSelector(selectPlayers);
  const teams = useAppSelector(selectTeams);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const isInitialMountRef = useRef(true);

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const fetchIndividualStats = useFetchIndividualStats();
  const handleOnSubmit = useHandleOnSubmit((values, config) => {
    fetchIndividualStats(values, config?.isPercentiles);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const handleToggleLeaguePercentile = () => {
    dispatch(setIsPercentilesActive(!isPercentilesActive));
    if (!isPercentilesActive) {
      handleOnSubmit({ isPercentiles: true });
    }
  };

  const commonHeader = [
    'Hokej.cz ID',
    'Jméno hráče',
    'Věk',
    'Držení hole',
    'Tým',
    'Pozice',
    'GP',
    'TOI',
  ];
  const tableRowCallback = (row: IIndividualStatsTableData): IExportRows => {
    const player = players.byId[row.player.id];
    const team = teams.byId[row.teamId];
    const hockeyczID = player.hokejczId ? player.hokejczId.toString() : '?';

    return {
      hokejczId: hockeyczID,
      playerName: row.player.displayName,
      age: player.age,
      stick: t(getPlayerStick(player.stick)).toString(),
      team: team.shortcut,
      position: t(getPlayerPositionShortcut(player.position)).toString(),
      gp: row.gp,
      toi: roundNumberTo2Decimals(row.toi / 60),
    };
  };

  if (isLoading) {
    return <Loading />;
  }

  const renderTableOrInfoBox = () => {
    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) {
      return errorInfoBox;
    }

    return (
      <CommonTable<IIndividualStatsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='players-page__tabs-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        isPercentilesActive={isPercentilesActive}
        isPercentilesLoading={isPercentilesLoading || ranks.isLoading}
        onClickLeaguePercentile={handleToggleLeaguePercentile}
        scrollSizePx={130}
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX('hráči-tabulka', commonHeader, tableRowCallback, data)
            }
          />
        }
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
        />
      )}
    </div>
  );
};
