import { Link, Outlet } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import HLLogo from '../../assets/logo.png';
import { NavigationBarButton, TabItem } from '../../components';
import { logout, selectAuth, selectNavigation, setShowHelpSidebar } from '../../features';
import { useLocationPaths } from '../../hooks';
import { ITranslationKeys } from '../../i18n/types';
import { HelpIcon, LogoutIcon } from '../../icons';
import { IRole } from '../../types';
import { createClassNames } from '../../utils';
import { NAVIGATION_PATHS } from '../constants';
import './NavigationBar.styles.scss';

const classNames = createClassNames('navigation-bar');

export const NavigationBar = () => {
  const { activeTab, activePage } = useLocationPaths();
  const { user } = useAppSelector(selectAuth);
  const { isLoadingMinimumInitialData } = useAppSelector(selectNavigation);

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleOpenHelpSidebar = () => {
    dispatch(setShowHelpSidebar(true));
  };

  return (
    <div
      className={['app-view', isLoadingMinimumInitialData ? ' app-view--disable-all' : ''].join('')}
      data-testid='app-view'
    >
      <div className={classNames()} data-testid='navigation-bar'>
        <div className={classNames('container')}>
          <Link to='/dashboard' className={classNames('container__dashboard-link')}>
            <img
              src={HLLogo}
              alt='HL Logo'
              className={classNames('container__dashboard-link__logo')}
            />
          </Link>
          <div className={classNames('container__item-list')}>
            {NAVIGATION_PATHS.map(path => (
              <TabItem
                key={path.label}
                isNavigationBar
                label={path.label}
                to={path.to}
                isActive={`/${activePage}` === path.to}
              />
            ))}
            {user?.role === IRole.admin && (
              <TabItem
                key={ITranslationKeys.tracking}
                isNavigationBar
                label={ITranslationKeys.tracking}
                to='/tracking'
              />
            )}
            {user?.role === IRole.admin && (
              <TabItem key='CMS' isNavigationBar label='CMS' to='/cms' />
            )}
          </div>
          <div className={classNames('container__nav-bar-buttons-list')}>
            <NavigationBarButton
              icon={<LogoutIcon />}
              label={ITranslationKeys.logout}
              onClick={handleLogout}
            />
            {!!activeTab ? (
              <NavigationBarButton
                icon={<HelpIcon />}
                label={ITranslationKeys.help}
                onClick={handleOpenHelpSidebar}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};
