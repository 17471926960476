import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  filteredTrendSelector,
  getTimePeriodFormationStats,
  getTimePeriodPlayerStats,
  selectMainFilter,
  selectTrend,
  selectVideomapsFilter,
} from '../../features';
import { IFormation, IMainFilterForm, ITimePeriodTableForm, ITrendRequestBody } from '../../types';
import { isAtLeastOnePlayerOn } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchTimePeriodPlayerStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);
  const { selectedPlayerItems } = useAppSelector(selectVideomapsFilter);
  const { tableDataBy } = useAppSelector(selectTrend);
  const filteredTrend = useAppSelector(filteredTrendSelector);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchTimePeriodPlayerStats = (
    data: Partial<IMainFilterForm>,
    trendData: ITimePeriodTableForm,
  ) => {
    const competitionsUuids = filteredParts.map(part => part.id);

    const filteredSelectedPlayers = selectedPlayerItems
      ? Object.values(selectedPlayerItems).filter(item => item.selectedPlayer)
      : [];
    const formation: IFormation[] = filteredSelectedPlayers.map(item => ({
      player: item.selectedPlayer?.value || '',
      on: item.isActive ?? false,
    }));

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: ITrendRequestBody = {
      ...requestBodyBase,
      formation: formation.length > 1 ? formation : undefined,
      metrics: normalizeMetrics(),
    };

    const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, false);
    const isSelectionValid =
      filteredParts.length > 0 && selectedTeam && selectedTeam.value !== 'all' && isSomePlayerOn;

    if (isSelectionValid) {
      const teamUuid = selectedTeam.value;
      if (formation.length === 1) {
        const sharedRequestParams = {
          competitionsUuids,
          teamUuid,
          playerUuid: formation[0].player,
        };

        if (tableDataBy === 'calendar') {
          trendData.timePeriodByDate.forEach((rowDate, index, array) => {
            dispatch(
              getTimePeriodPlayerStats({
                ...sharedRequestParams,
                body: { ...requestBody, dateFrom: rowDate?.from, dateTo: rowDate?.to },
                isLast: index === array.length - 1,
                rowIndex: index,
              }),
            );
          });
        } else {
          trendData.timePeriodByGames.forEach((row, index, array) => {
            const matches = filteredTrend
              .map(item => item.gameId)
              .slice(row.from ? row.from - 1 : undefined, row.to);

            dispatch(
              getTimePeriodPlayerStats({
                ...sharedRequestParams,
                body: { ...requestBody, matches },
                isLast: index === array.length - 1,
                rowIndex: index,
              }),
            );
          });
        }
      } else {
        const sharedRequestParams = {
          competitionsUuids,
          teamUuid,
        };

        if (tableDataBy === 'calendar') {
          trendData.timePeriodByDate.forEach((rowDate, index, array) => {
            dispatch(
              getTimePeriodFormationStats({
                ...sharedRequestParams,
                body: { ...requestBody, dateFrom: rowDate?.from, dateTo: rowDate?.to },
                isLast: index === array.length - 1,
                rowIndex: index,
              }),
            );
          });
        } else {
          trendData.timePeriodByGames.forEach((row, index, array) => {
            const matches = filteredTrend
              .map(item => item.gameId)
              .slice(row.from ? row.from - 1 : undefined, row.to);

            dispatch(
              getTimePeriodFormationStats({
                ...sharedRequestParams,
                body: { ...requestBody, matches },
                isLast: index === array.length - 1,
                rowIndex: index,
              }),
            );
          });
        }
      }
    }
  };

  return fetchTimePeriodPlayerStats;
};
