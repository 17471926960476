import { ColumnDef, SortingState, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  BasicColumnCell,
  BasicColumnHeader,
  SelectInput,
  Switch,
  TcmsButton,
} from '../../../../components';
import { notFilledOutOption, roleOptions } from '../../../../constants';
import {
  IChangeIdentityTeamOrMediaParams,
  changeIdentityRole,
  changeIdentityTeamOrMedia,
  deleteIdentity,
  enableIdentity,
  teamAndMediaToSetUserOptionsSelector,
} from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { TcmsEditIcon } from '../../../../icons';
import { ICmsUserFilterForm, IIdentitiesTableData, IRole, ISelectOption } from '../../../../types';
import {
  createClassNames,
  createIndexColumn,
  teamAndMediaOptionsByRole,
  teamOrMediaTypeByRole,
} from '../../../../utils';
import './columns.styles.scss';

const classNames = createClassNames('users-content-columns');

const columnHelper = createColumnHelper<IIdentitiesTableData>();

const initialSorting: SortingState = [
  {
    id: 'id',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IIdentitiesTableData, any>[] = [
  columnHelper.accessor('username', {
    header: props => (
      <BasicColumnHeader
        {...props}
        translationKey={ITranslationKeys.user}
        justifyContent='flex-start'
        allowSortIcon
        variant='tcms'
      />
    ),
    cell: props => {
      const nameValue = [props.row.original.name, props.row.original.surname].join(' ');
      return (
        <BasicColumnCell {...props} variant='tcms'>
          <div className={[classNames(), classNames('username-cell')].join(' ')}>
            {nameValue} <span>({props.row.original.username})</span>
          </div>
        </BasicColumnCell>
      );
    },
  }),
];

export const useColumnsConfig = (
  submitValues: ICmsUserFilterForm,
  handleOpenChangePasswordModal: (userId: string) => void,
) => {
  const teamAndMediaToSetUserOptions = useAppSelector(teamAndMediaToSetUserOptionsSelector);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { selectedRole } = submitValues;

  const handleChangeUserTeamOrMedia = (option: ISelectOption, userId: string, role: IRole) => {
    const teamOrMediaId = teamAndMediaToSetUserOptions.find(team => team.value === option.value)
      ?.value;

    if (!teamOrMediaId && option.value !== '') {
      toast.error(t(ITranslationKeys.teamOrMediaNotExistsErrorMessage), {
        toastId: ITranslationKeys.teamOrMediaNotExistsErrorMessage,
      });
      return;
    }

    toast.promise(
      dispatch(
        changeIdentityTeamOrMedia({
          identityId: userId,
          newTeamOrMediaId: teamOrMediaId || null,
          type: teamOrMediaTypeByRole(option, role),
        }),
      ).unwrap(),
      {
        pending: t(ITranslationKeys.changeInProgress),
        success: t(ITranslationKeys.videocoachChanged).toString(),
        error: t(ITranslationKeys.saveDataFailedMessage).toString(),
      },
    );
  };

  const handleChangeUserRole = (
    option: ISelectOption,
    userId: string,
    prevRole: IRole,
    teamId: string | null,
    media: string | null,
  ) => {
    if (option.value === prevRole) return;

    toast.promise(
      dispatch(changeIdentityRole({ identityId: userId, newRole: option.value as IRole })).unwrap(),
      {
        pending: t(ITranslationKeys.changeInProgress),
        success: t(ITranslationKeys.roleChanged).toString(),
        error: t(ITranslationKeys.saveDataFailedMessage).toString(),
      },
    );

    if (option.value === IRole.admin || option.value === IRole.test) return;

    const sharedParams: IChangeIdentityTeamOrMediaParams = {
      identityId: userId,
      newTeamOrMediaId: null,
    };

    if (option.value === IRole.user || option.value === IRole.client) {
      if (teamId) dispatch(changeIdentityTeamOrMedia({ ...sharedParams, type: 'team' }));
      if (media) dispatch(changeIdentityTeamOrMedia({ ...sharedParams, type: 'media' }));
    }

    if (option.value === IRole.media) {
      if (teamId) dispatch(changeIdentityTeamOrMedia({ ...sharedParams, type: 'team' }));
    }

    if (option.value === IRole.team) {
      if (media) dispatch(changeIdentityTeamOrMedia({ ...sharedParams, type: 'media' }));
    }
  };

  const handleToggleUser = (isUserDisabled: boolean, userId: string) => {
    if (isUserDisabled) {
      toast.promise(dispatch(enableIdentity(userId)).unwrap(), {
        pending: t(ITranslationKeys.changeInProgress),
        success: t(ITranslationKeys.userActivated).toString(),
        error: t(ITranslationKeys.saveDataFailedMessage).toString(),
      });
    } else {
      toast.promise(dispatch(deleteIdentity(userId)).unwrap(), {
        pending: t(ITranslationKeys.changeInProgress),
        success: t(ITranslationKeys.userDeactivated).toString(),
        error: t(ITranslationKeys.saveDataFailedMessage).toString(),
      });
    }
  };

  const columns = useMemo(
    () => {
      const indexColumn = columnHelper.accessor('id', {
        header: props => (
          <BasicColumnHeader
            {...props}
            translationKey={ITranslationKeys.id}
            allowSortIcon
            variant='tcms'
          />
        ),
        cell: props => (
          <BasicColumnCell {...props} variant='tcms'>
            <div className={[classNames(), classNames('id-cell')].join(' ')}>
              {createIndexColumn(props)}
            </div>
          </BasicColumnCell>
        ),
        enableSorting: false,
      });

      const dynamicColumns: ColumnDef<IIdentitiesTableData, any>[] = [
        columnHelper.accessor('role', {
          header: props => (
            <BasicColumnHeader
              {...props}
              translationKey={ITranslationKeys.role}
              justifyContent='flex-start'
              allowSortIcon
              variant='tcms'
            />
          ),
          cell: props => {
            const { id, role, teamId, media } = props.row.original;

            return (
              <BasicColumnCell {...props} variant='tcms'>
                <div className={[classNames(), classNames('role-cell')].join(' ')}>
                  <SelectInput
                    onChange={value => handleChangeUserRole(value, id, role, teamId, media)}
                    selected={roleOptions.find(option => option.value === props.getValue())}
                    options={roleOptions}
                    placeholder={ITranslationKeys.defaultSelectPlaceholder}
                    variant='filter'
                  />
                </div>
              </BasicColumnCell>
            );
          },
        }),
        // teamId and media column
        columnHelper.accessor('teamId', {
          header: props => (
            <BasicColumnHeader
              {...props}
              translationKey={ITranslationKeys.teamMedia}
              justifyContent='flex-start'
              allowSortIcon
              variant='tcms'
            />
          ),
          cell: props => {
            const { id, role, teamId, media } = props.row.original;

            const options = teamAndMediaOptionsByRole(teamAndMediaToSetUserOptions, role);
            const selected = teamOrMediaSelectedByRole(options, role, teamId, media);

            return (
              <BasicColumnCell {...props} variant='tcms'>
                <div className={[classNames(), classNames('videocoach-cell')].join(' ')}>
                  <SelectInput
                    onChange={value => handleChangeUserTeamOrMedia(value, id, role)}
                    selected={selected || notFilledOutOption}
                    options={options}
                    placeholder={ITranslationKeys.defaultSelectPlaceholder}
                    variant='filter'
                    disabled={role === IRole.user || role === IRole.client}
                  />
                </div>
              </BasicColumnCell>
            );
          },
        }),
        columnHelper.accessor('disabled', {
          header: props => (
            <BasicColumnHeader
              {...props}
              translationKey={ITranslationKeys.activeUser}
              justifyContent='center'
              allowSortIcon
              variant='tcms'
            />
          ),
          cell: props => (
            <BasicColumnCell {...props} variant='tcms'>
              <div className={[classNames(), classNames('disabled-cell')].join(' ')}>
                <Switch
                  checked={!props.getValue()}
                  onClick={() => handleToggleUser(props.getValue(), props.row.original.id)}
                />
              </div>
            </BasicColumnCell>
          ),
        }),
        columnHelper.display({
          id: 'index',
          header: '',
          cell: props => (
            <div className={[classNames(), classNames('change-password-cell')].join(' ')}>
              <TcmsButton
                label={ITranslationKeys.changePassword}
                variant='text'
                color='blue'
                onClick={() => handleOpenChangePasswordModal(props.row.original.id)}
                iconComponent={<TcmsEditIcon />}
                iconPosition='left'
              />
            </div>
          ),
        }),
      ];

      return [indexColumn].concat(fixedColumns).concat(dynamicColumns);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRole, teamAndMediaToSetUserOptions],
  );

  return {
    columns,
    initialSorting,
  };
};

const teamOrMediaSelectedByRole = (
  options: ISelectOption[],
  role: IRole,
  teamId: string | null,
  media: string | null,
) => {
  if (role === IRole.admin || role === IRole.test) {
    return options.find(option => option.value === media || option.value === teamId);
  }

  if (role === IRole.media) {
    return options.find(option => option.value === media);
  }

  if (role === IRole.team) {
    return options.find(option => option.value === teamId);
  }

  return undefined;
};
