import {
  IAccessLog,
  IAccessLogFilterForm,
  IEventScreenLog,
  IEventScreenLogFilterForm,
  IIdentity,
  IRole,
  ISelectOption,
  ITeamRecord,
  ITrackingUserFilterForm,
} from '../../types';

const filterAccessLogOrEventLogByTeamOrMedia = (
  accessLogData: (IAccessLog | IEventScreenLog)[],
  teams: ITeamRecord,
  teamOrMedia?: ISelectOption[],
  role?: ISelectOption,
) => {
  if (!role || !teamOrMedia || teamOrMedia.some(item => item.value === 'all')) {
    return accessLogData;
  }

  if (role.value === IRole.media) {
    return accessLogData.filter(data => teamOrMedia.some(item => item.value === data.media));
  }

  return accessLogData.filter(data =>
    teamOrMedia.some(item => {
      const team = Object.values(teams).find(team => team.shortcut === item.value);
      return data.team === team?.id;
    }),
  );
};

export const filterAccessLogDataByFilterForm = (
  accessLogData: IAccessLog[] | undefined,
  filterValues: IAccessLogFilterForm,
  teamRecord: ITeamRecord,
) => {
  if (!accessLogData) return [];

  const { selectedRole, selectedTeamOrMedia } = filterValues;
  const filteredAccessLogByTeam = filterAccessLogOrEventLogByTeamOrMedia(
    accessLogData,
    teamRecord,
    selectedTeamOrMedia,
    selectedRole,
  );

  return accessLogData.filter(item => filteredAccessLogByTeam.includes(item));
};

export const filterEventScreenLogDataByFilterForm = (
  screenLogData: IEventScreenLog[] | undefined,
  filterValues: IEventScreenLogFilterForm,
  teamRecord: ITeamRecord,
) => {
  if (!screenLogData) return [];

  const { selectedRole, selectedTeamOrMedia } = filterValues;
  const filteredEventLogByTeam = filterAccessLogOrEventLogByTeamOrMedia(
    screenLogData,
    teamRecord,
    selectedTeamOrMedia,
    selectedRole,
  );

  return screenLogData.filter(item => filteredEventLogByTeam.includes(item));
};

const filterUserLogByRole = (identities: IIdentity[], role?: ISelectOption) => {
  if (!role || role.value === 'all') {
    return identities;
  }

  if (role.value === IRole.team) {
    return identities.filter(item => item.teamId);
  }

  return identities.filter(item => item.role === role.value);
};

const filterUserLogByUser = (accessLogData: IIdentity[], username: string) => {
  if (!username || !username.trim()) {
    return accessLogData;
  }

  const usernameLowerCase = username.toLowerCase();
  return accessLogData.filter(data => data.username.toLowerCase().includes(usernameLowerCase));
};

export const filterUserLogDataByFilterForm = (
  identities: IIdentity[],
  filterValues: ITrackingUserFilterForm,
) => {
  const { selectedRole, userFilterText } = filterValues;
  const filteredIdentitiesLogByRole = filterUserLogByRole(identities, selectedRole);
  const filteredIdentitiesLogByUser = filterUserLogByUser(identities, userFilterText);

  return identities.filter(
    item =>
      filteredIdentitiesLogByRole.includes(item) && filteredIdentitiesLogByUser.includes(item),
  );
};
