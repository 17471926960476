import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectGamelog, selectTeams } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IGamelog,
  IGamelogIndividualStatsTableData,
  IMetricWithDatasetMetric,
  ITeamRecord,
} from '../../../../types';
import { getMetricsWithValue } from '../../../../utils';

const getTableData = (
  gamelog: IGamelog[],
  teamRecord: ITeamRecord,
  metrics: IMetricWithDatasetMetric[],
) => {
  const tableData: IGamelogIndividualStatsTableData[] = gamelog.map(game => {
    const homeTeam = teamRecord[game.info.homeTeamId];
    const awayTeam = teamRecord[game.info.awayTeamId];

    return {
      entityId: game.info.homeTeamId,
      homeTeamId: game.info.homeTeamId,
      gameTeams: {
        homeTeam,
        awayTeam,
      },
      awayTeamId: game.info.awayTeamId,
      date: game.info.date,
      score: game.info.score,
      toi: game.stats.toi,
      stats: getMetricsWithValue(metrics, game.stats),
    };
  });

  return tableData;
};

export const useDataForTable = () => {
  const gamelog = useAppSelector(selectGamelog);
  const teams = useAppSelector(selectTeams);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IGamelogIndividualStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      return getTableData(Object.values(gamelog.byId), teams.byId, filteredMetrics);
    }

    return [];
  }, [gamelog.byId, teams.byId, getFilteredMetrics]);

  return data;
};
