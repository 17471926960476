import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n/i18n';
import { ITranslationKeys } from '../../../i18n/types';
import { CloseBlueIcon } from '../../../icons';
import { IHelpFunctionsInfo, IHelpTextItem, ILanguage } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { FunctionsItem } from '../FunctionsItem';
import { TipsContent } from '../TipsContent';
import './Sidebar.styles.scss';

export interface ISidebarProps {
  isOpened: boolean;
  title: string;
  description: string;
  tips: IHelpTextItem[];
  functions: IHelpFunctionsInfo[];
  onHelpClose: () => void;
}

const classNames = createClassNames('sidebar');

export const Sidebar: FC<ISidebarProps> = ({
  isOpened,
  title,
  description,
  tips,
  functions,
  onHelpClose,
}) => {
  const { t } = useTranslation();
  const language = i18n.language as ILanguage;

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isOpened,
        }),
      })}
    >
      <span className={classNames('close')} onClick={onHelpClose}>
        {t(ITranslationKeys.closeHelp)}
        <CloseBlueIcon />
      </span>
      <h3>{title}</h3>
      <p
        className={classNames('description')}
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
      {tips.length > 0 ? <TipsContent items={tips} /> : null}
      <div>
        {functions.map(item => (
          <FunctionsItem key={item.id} title={item.title[language]} descriptions={item[language]} />
        ))}
      </div>
    </div>
  );
};
