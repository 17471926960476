import { AxiosError } from 'axios';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { logosPaths } from '../../../constants';
import { ITranslationKeys } from '../../../i18n/types';
import {
  CheckOffIcon,
  CheckOnIcon,
  EditIcon,
  MinusBlueIcon,
  PlusBlueIcon,
  SaveIcon,
} from '../../../icons';
import { ILanguage } from '../../../types';
import { IClip } from '../../../types/accountManagement.types';
import {
  changeEmail,
  changeLanguage,
  changeRequestFailed,
  createClassNames,
  deleteEmail,
  getPlayerPositionShortcut,
  validateEmail,
} from '../../../utils';
import { Input } from '../../Input';
import { HokejPlayerManagementRolldown } from '../HokejPlayerManagementRolldown';
import './HokejPlayerManagementRow.styles.scss';

export interface IHokejPlayerManagementRowProps {
  /**
   * Path (uuid) of player's team.
   */
  logoPath: string;
  /**
   * Player's id;
   */
  playerId: string;
  /**
   * Post on which player plays.
   */
  playerPost: string;
  /**
   * Player's playing number.
   */
  playerNumber: string;
  /**
   * Player's first name.
   */
  playerFirstName: string;
  /**
   * Player's last name.
   */
  playerLastName: string;
  /**
   * Player's e-mail.
   */
  playerEmail: string;
  /**
   * Array of player's clips.
   */
  clips: IClip[];
  /**
   * Fires click hook when clip is selected.
   * @param clip Clip to be selected
   */
  onClipSelect?: (clip: IClip) => void;
  /**
   * Fires click hook when clip is requested to be deleted.
   * @param clip Clip to be deleted
   */
  onClipDelete?: (clip: IClip) => void;
}

const classNames = createClassNames('hokej-player-management-row');

/**
 * HokejPlayerManagementRow UI component.
 */
export const HokejPlayerManagementRow: FC<IHokejPlayerManagementRowProps> = ({
  logoPath,
  playerPost,
  playerId,
  playerNumber,
  playerFirstName,
  playerLastName,
  playerEmail,
  clips,
  onClipSelect,
  onClipDelete,
}) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [showRolldown, setShowRolldown] = useState<boolean>(false);
  const [language, setLanguage] = useState<ILanguage>('cz');

  const handleStartEditing = () => setEditing(true);

  const handleSave = () => {
    setEditing(false);
    if (email && validateEmail(email)) {
      handleValidEmail();
    } else {
      handleInvalidEmail();
    }
  };

  const handleValidEmail = () => {
    setIsEmailValid(true);
    changeEmail(playerId, email)
      .then(() => toast.success(t(ITranslationKeys.emailEdited), { toastId: 'emailEdited' }))
      .catch((error: AxiosError) =>
        changeRequestFailed(
          t(ITranslationKeys.cantChangePlayerEmailErrorMessage),
          ITranslationKeys.cantChangePlayerEmailErrorMessage,
          error,
        ),
      );
  };

  const handleInvalidEmail = () => {
    setIsEmailValid(false);
    if (email === '') {
      deleteEmail(playerId)
        .then(res => {
          toast.success(t(ITranslationKeys.emailDeleted), { toastId: 'emailDeleted' });
        })
        .catch((error: AxiosError) => {
          changeRequestFailed(
            t(ITranslationKeys.cantDeletePlayerEmailErrorMessage),
            ITranslationKeys.cantDeletePlayerEmailErrorMessage,
            error,
          );
        });
    } else {
      toast.error(t(ITranslationKeys.enterValidEmail), { toastId: 'enterValidEmail' });
    }
    setEmail(t(ITranslationKeys.enterEmail)!);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleShowRolldown = () => {
    setShowRolldown(prevState => !prevState);
  };

  const handleChangeLanguage = () => {
    changeLanguage(playerId, language === 'cz' ? 'en' : 'cz')
      .then(() => {
        toast.success(t(ITranslationKeys.languageChanged), { toastId: 'languageChanged' });
        setLanguage(prevState => (prevState === 'cz' ? 'en' : 'cz'));
      })
      .catch((error: AxiosError) => {
        changeRequestFailed(
          t(ITranslationKeys.cantChangePlayerLanguageErrorMessage),
          ITranslationKeys.cantChangePlayerLanguageErrorMessage,
          error,
        );
      });
  };

  useEffect(() => {
    if (playerEmail) {
      setEmail(playerEmail);
      setIsEmailValid(true);
    } else {
      setEmail(t(ITranslationKeys.enterEmail)!);
      setIsEmailValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerEmail]);

  // TODO: Uncomment when resolving the BE language error
  // useEffect(() => {
  //   getLanguage(playerId)
  //     .then((res: ILanguageResponse) => {
  //       setLanguage(res.data.language);
  //     })
  //     .catch(err => console.log('Get language err:', err));
  // }, [playerId]);

  return (
    <>
      <tr className={classNames()}>
        <td>
          <p>{t(getPlayerPositionShortcut(playerPost))}</p>
        </td>
        <td>
          <div className={classNames('player')}>
            <img src={logosPaths[logoPath]} alt='team-logo' />
            <p>
              #{playerNumber} {playerLastName} {playerFirstName[0]}.
            </p>
          </div>
        </td>
        <td>
          <div className={classNames('email')}>
            {editing || !isEmailValid ? (
              <CheckOffIcon width={16} height={16} />
            ) : (
              <CheckOnIcon width={16} height={16} />
            )}
            <Input value={email} disabled={!editing} onChange={handleChange} />
            {editing ? (
              <SaveIcon
                className={classNames('icon-button')}
                onClick={handleSave}
                width={16}
                height={16}
              />
            ) : (
              <EditIcon
                className={classNames('icon-button')}
                onClick={handleStartEditing}
                width={16}
                height={16}
              />
            )}
          </div>
        </td>
        <td>
          <div className={classNames('language')}>
            <p>CZ</p>
            <button
              className={classNames('language-switch', { right: language === 'en' })}
              onClick={handleChangeLanguage}
              role='switch'
              aria-checked={language === 'en'}
            >
              <div className={classNames('language-inner', { right: language === 'en' })}></div>
            </button>
            <p>EN</p>
          </div>
        </td>
        <td>
          <div className={classNames('clips')}>
            {clips.length > 0 && (
              <>
                <p>{t(ITranslationKeys.sentClips)}</p>
                {showRolldown ? (
                  <MinusBlueIcon
                    className={classNames('clips__icon-button')}
                    width={16}
                    height={16}
                    onClick={handleShowRolldown}
                  />
                ) : (
                  <PlusBlueIcon
                    className={classNames('clips__icon-button')}
                    width={16}
                    height={16}
                    onClick={handleShowRolldown}
                  />
                )}
              </>
            )}
          </div>
        </td>
      </tr>
      {showRolldown &&
        clips.map((clip, index) => (
          <HokejPlayerManagementRolldown
            {...clip}
            key={clip.id}
            first={index === 0}
            last={index === clips.length - 1}
            onPlayIconClick={() => onClipSelect?.(clip)}
            onTrashIconClick={() => onClipDelete?.(clip)}
          />
        ))}
    </>
  );
};
