import { createSelector } from '@reduxjs/toolkit';

import { countAllGamesTotalToi, createGameEntity, filterGames } from '../../../utils';
import {
  selectGames,
  selectGoalkeepersFilter,
  selectPlayers,
  selectVideomapsFilter,
} from '../../selectors';
import { filteredShotsByEntitySelector, filteredShotsSelector } from '../../shots';

export const filteredGoalkeepersGamesSelector = createSelector(
  [selectVideomapsFilter, selectGames],
  (videomapsFilterState, { games }) => filterGames(videomapsFilterState.selectedGames, games.byId),
);

export const filteredGoalkeepersGamesTotalToiSelector = createSelector(
  [filteredGoalkeepersGamesSelector],
  filteredGames => countAllGamesTotalToi(filteredGames),
);

export const filteredGoalkeepersShotsBySavesSelector = createSelector(
  [selectGoalkeepersFilter, filteredShotsByEntitySelector],
  ({ saves }, filteredShotsByEntity) => {
    if (saves.value === 'all') return filteredShotsByEntity;

    return filteredShotsByEntity.filter(shot => shot.saveType === saves.value);
  },
);

export const filteredGoalkeepersShotsByStickGripSelector = createSelector(
  [selectGoalkeepersFilter, selectPlayers, filteredShotsByEntitySelector],
  ({ stickGrip }, players, filteredShotsByEntity) => {
    const { byId, allIds } = players;

    if (!stickGrip || stickGrip.value === 'all') return filteredShotsByEntity;

    return filteredShotsByEntity.filter(shot => {
      const playerUuid = allIds.find(id => shot.playerId === id);
      if (!playerUuid) return false;

      const player = byId[playerUuid];
      if (!player) return false;

      return player.stick === stickGrip.value;
    });
  },
);

export const filteredGoalkeepersShotsSelector = createSelector(
  [
    filteredShotsSelector,
    filteredGoalkeepersShotsBySavesSelector,
    filteredGoalkeepersShotsByStickGripSelector,
  ],
  (filteredShots, filteredShotsBySaves, filteredShotsByStick) => {
    const filteredShotsByAll = filteredShots.filter(
      shot => filteredShotsBySaves.includes(shot) && filteredShotsByStick.includes(shot),
    );

    return filteredShotsByAll;
  },
);

export const filteredGoalkeepersShotsGameEntitySelector = createSelector(
  [selectVideomapsFilter, filteredGoalkeepersShotsSelector],
  ({ selectedGames }, filteredShots) => createGameEntity(selectedGames, filteredShots),
);

export const filteredGoalkeepersFilterDataSelector = createSelector(
  [
    filteredGoalkeepersShotsSelector,
    filteredGoalkeepersShotsGameEntitySelector,
    filteredGoalkeepersGamesSelector,
    filteredGoalkeepersGamesTotalToiSelector,
  ],
  (
    filteredGoalkeepersShots,
    filteredGoalkeepersShotsGameEntity,
    filteredGoalkeepersGames,
    filteredGoalkeepersGamesTotalToi,
  ) => {
    return {
      filteredGoalkeepersShots,
      filteredGoalkeepersShotsGameEntity,
      filteredGoalkeepersGames,
      filteredGoalkeepersGamesTotalToi,
    };
  },
);
