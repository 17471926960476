import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Caption,
  CommonTable,
  DataSettingsModal,
  Loading,
  SelectInput,
  TableToolbar,
  VideoCenterModal,
  VideoShiftsSelectList,
} from '../../../../components';
import {
  DEFAULT_CONTENT_TEMPLATE_NAMES,
  defaultTemplate,
  gamePartDefaultOption,
} from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getGamesTablesPlayers,
  resetDataSettingsFilterState,
  selectDataSettingsFilter,
  selectGames,
  selectMainFilter,
  selectMetrics,
  selectTableFilter,
  selectTeams,
  selectVideoShifts,
  setCustomMetrics,
  setDataTemplate,
  setGamePart,
  shiftVideoRecordWithEditsSelector,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useHandleOnSubmit,
  useNormalizeMetrics,
  usePrepareBaseRequestBody,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
  useVideoCenterShifts,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IGamesTablesPlayerTableData,
  IGamesTablesStatsRequestBody,
  IMainFilterForm,
  ISelectOption,
} from '../../../../types';
import {
  createClassNames,
  createTeamSortingOptions,
  getShiftVideoOptions,
  getTimeFromGamePart,
} from '../../../../utils';
import './GamePlayersContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export const useFetchGamePlayersStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { gamePart } = useAppSelector(selectTableFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedGame } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchGamePlayers = (data: Partial<IMainFilterForm>) => {
    if (!selectedGame) return;

    const competitionsUuids = filteredParts.map(part => part.id);
    const timeFromTo = getTimeFromGamePart(gamePart);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IGamesTablesStatsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(DEFAULT_CONTENT_TEMPLATE_NAMES.players),
      timeFrom: timeFromTo?.timeFrom,
      timeTo: timeFromTo?.timeTo,
    };
    dispatch(
      getGamesTablesPlayers({
        competitionsUuids,
        matchUuid: selectedGame.id,
        teamUuid: selectedGame.homeTeamId,
        body: requestBody,
      }),
    );
  };

  return fetchGamePlayers;
};

const classNames = createClassNames('game-players-content');

export const GamePlayersContent = () => {
  const { countOfPlayers, selectedGame } = useAppSelector(selectMainFilter);
  const { gamePart, dataTemplate } = useAppSelector(selectTableFilter);
  const { gamesTables, games } = useAppSelector(selectGames);
  const { byId } = useAppSelector(selectTeams);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const { shifts, open: openShifts } = useAppSelector(selectVideoShifts);
  const { videoRecord } = useAppSelector(shiftVideoRecordWithEditsSelector);
  const { playersLoading } = gamesTables;
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { playPlayerShiftVideos, handleClose, handleSetVideoEdit, handleResetVideoEdit } =
    useVideoCenterShifts();

  const filteredTeamOptions: ISelectOption<any>[] = createTeamSortingOptions(byId, selectedGame);
  const [selectedFilterTeamId, setSelectedFilterTeamId] = useState<
    ISelectOption<any> | undefined
  >();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable(selectedFilterTeamId?.value);
  const { columns, columnPinning, initialSorting } = useColumnsConfig(
    centerPartWidth,
    playPlayerShiftVideos,
  );

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const isInitialMountRef = useRef(true);

  const fetchGamePlayersStats = useFetchGamePlayersStats();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGamePlayersStats(values);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
    disablePositionRefetch: true,
  });

  useRefetchContent({ handleOnSubmit, isLoading: playersLoading, disableInitialRefetch: true });

  useEffect(() => {
    setSelectedFilterTeamId(filteredTeamOptions[0]);
    dispatch(setGamePart(gamePartDefaultOption));

    if (selectedGame) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  useEffect(() => {
    handleOnSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countOfPlayers, gamePart, dataTemplate]);

  useEffect(() => {
    return () => {
      dispatch(setDataTemplate(defaultTemplate));
      dispatch(setCustomMetrics(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (playersLoading || !games.isAllGamesLoaded) {
    return <Loading />;
  }

  if (!selectedGame) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectGame)}</div>;
  }

  const renderTableOrInfoBox = () => {
    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) return errorInfoBox;

    return (
      <CommonTable<IGamesTablesPlayerTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='games-page__tabs-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        enableCountOfPlayers
        enableGamePartField
        extraElement={
          <div>
            <Caption label={ITranslationKeys.filterTeam} />
            <SelectInput
              variant='filter'
              options={filteredTeamOptions}
              selected={selectedFilterTeamId}
              onChange={setSelectedFilterTeamId}
            />
          </div>
        }
        scrollSizePx={130}
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(resetDataSettingsFilterState())}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
        />
      )}
      <VideoCenterModal
        categoryKey={ITranslationKeys.shifts}
        open={openShifts}
        renderList={params => (
          <VideoShiftsSelectList {...params} videosRecord={videoRecord} disableCategoryKey />
        )}
        videoRecord={videoRecord}
        originalVideoRecord={shifts}
        options={getShiftVideoOptions(shifts)}
        {...{ handleClose, handleSetVideoEdit, handleResetVideoEdit }}
      />
    </div>
  );
};
