import { useRef } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  DataSettingsModal,
  Loading,
  SubrowsTable,
  TableToolbar,
} from '../../../../components';
import {
  selectDataSettingsFilter,
  selectMetrics,
  selectTeams,
  selectTeamsFormation,
  selectWowy,
  setIsTeamsFormationPercentilesActive,
  setIsWowyPercentilesActive,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchTeamsFormation,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { ITeamsFormationTableData } from '../../../../types';
import { createClassNames, handleExportToXLSX, roundNumberTo2Decimals } from '../../../../utils';
import './FormationsContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';
import { useExtraRowHeaderRender } from './useExtraRowHeaderRender';

const classNames = createClassNames('formations-content');

export const FormationsContent = () => {
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { isLoading, isTeamsFormationPercentilesActive, isTeamsFormationPercentilesLoading } =
    useAppSelector(selectTeamsFormation);
  const wowyState = useAppSelector(selectWowy);
  const { metrics } = useAppSelector(selectMetrics);
  const teams = useAppSelector(selectTeams);

  const dispatch = useAppDispatch();

  const isInitialMountRef = useRef(true);

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const { selectedPlayerData, data } = useDataForTable();
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const extraRowHeaderRender = useExtraRowHeaderRender(selectedPlayerData);

  const fetchTeamsFormation = useFetchTeamsFormation();
  const handleOnSubmit = useHandleOnSubmit((values, config) => {
    fetchTeamsFormation(values, config?.isPercentiles);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const handleToggleLeaguePercentile = () => {
    dispatch(setIsTeamsFormationPercentilesActive(!isTeamsFormationPercentilesActive));
    dispatch(setIsWowyPercentilesActive(!wowyState.isWowyPercentilesActive));
    if (!isTeamsFormationPercentilesActive) {
      handleOnSubmit({ isPercentiles: true });
    }
  };

  const commonHeader = ['Formace', 'Tým', 'GP', 'TOI'];
  const tableRowCallback = (row: ITeamsFormationTableData) => {
    const attackersNames = row.players.firstRow.map(player => player.displayName);
    const defendersNames = row.players.secondRow.map(player => player.displayName);
    const team = teams.byId[row.teamId];
    const allNames = [...attackersNames, ...defendersNames];

    return {
      players: allNames.join(', '),
      team: team.shortcut,
      gp: row.gp,
      toi: roundNumberTo2Decimals(row.toi / 60),
    };
  };

  const isLoadingCondition = isLoading || wowyState.isLoading;

  const renderTableOrInfoBox = () => {
    if (isLoadingCondition) {
      return <Loading />;
    }

    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) {
      return errorInfoBox;
    }

    return (
      <SubrowsTable<ITeamsFormationTableData>
        centerPartRef={centerTablePartRef}
        cellsHeight={67}
        extraRowHeaderRender={extraRowHeaderRender}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='formations-page__formations-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        isPercentilesActive={isTeamsFormationPercentilesActive}
        isPercentilesLoading={isTeamsFormationPercentilesLoading}
        onClickLeaguePercentile={handleToggleLeaguePercentile}
        scrollSizePx={130}
        isLoading={isLoadingCondition}
        disablePositionSelection
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX('formace-tabulka', commonHeader, tableRowCallback, data)
            }
          />
        }
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
        />
      )}
    </div>
  );
};
